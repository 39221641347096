import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
