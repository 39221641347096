<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="text-left font-weight-bold">
        Where does it go?
        <router-link to="/location">{{ location.name }}</router-link>
        <v-autocomplete
          :items="items"
          v-model="selectedItem"
          item-text="name"
          return-object
          :multiple="false"
          append-icon="search"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-for="(value, name) in selectedItem" :key="name">
          <div v-if="name.startsWith('msg')" v-html="value"></div>
          <br />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import demoData from "../demo-data/data.json";

export default {
  name: "HelloWorld",

  data: () => ({
    items: demoData,
    selectedItem: null
  }),

  props: {
    location: Object
  }
};
</script>
