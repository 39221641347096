<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8" class="text-left font-weight-bold">
        <router-link to="/">Back to search</router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" class="text-left font-weight-bold"
        >Current location: {{ $store.state.currentLocation.name }}
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" sm="8" class="text-left font-weight-bold">
        <vuetify-google-autocomplete
          id="map"
          class="form-control"
          placeholder="Set new location"
          types="(cities)"
          @placechanged="selectAddress"
        ></vuetify-google-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Location",
  methods: {
    selectAddress({ name, place_id }) {
      console.log("selectAddress", name, place_id);
      this.$store.commit("setLocation", { name, place_id });
    }
  }
};
</script>
