import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentLocation: { name: "foobarland" }
  },
  mutations: {
    setLocation(state, payload) {
      state.currentLocation = payload;
    }
  },
  actions: {},
  modules: {}
});
