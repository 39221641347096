import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Location from "../views/Location.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home"
    },
    {
      path: "/location",
      component: Location
    },
    {
      path: "/*",
      redirect: "/"
    }
  ]
});

export default router;
